<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="6">
        <v-card outlined class="card-hover fill-height" v-if="integrations.WALLET">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.blockchainConfig") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              class="mx-4"
              :src="require('@/assets/icons/chainlink.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
          <v-card-text class="ma-0 pa-8">
            <v-switch
              v-model="item.isVirtual"
              :disabled="action === 'show'"
              class="
                ma-0
                pa-0
                d-flex
                justify-space-between
                align-center align-content-center
              "
              color="primary"
              hide-details
            >
              <template v-slot:prepend>
                <v-label ref="label">{{
                  $t("storage.fields.isVirtual")
                }}</v-label>
              </template>
            </v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import _ from "lodash"
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {},
    name: "TabConfigurationLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      loadingSendQuery: false,
      document: {
        type: "",
        criteria: "",
        datasets: [],
      },
      integrations: {
        WALLET: null,
      },
    }),

    async created() {
      if (this.item.id) {
        this.integrations.WALLET = _.find(PROFILE.integrationsCompany, {
          integrationCompanyType: "WALLET",
        })

        // await this.handleIntegration()
      }
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      ...mapActions({}),
    },
  }
</script>

<style scoped lang="scss">
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg)
      brightness(400%) contrast(88%);
  }
  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  :deep(.v-input--selection-controls.v-input) {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    align-items: center !important;
  }

  :deep(.v-input--hide-details > .v-input__control > .v-input__slot) {
    display: contents;
  }

  :deep(.v-input__control) {
    align-items: flex-end;
  }

  :deep(.theme--light.v-label) {
    left: 0px;
    right: auto;
    position: relative;
    width: 250px;
  }
</style>
