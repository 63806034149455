<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card outlined class="card-hover fill-height">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.extraData") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              class="mx-4"
              :src="require('@/assets/icons/firstline.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
            <v-card-text>
              <v-row v-if="action === 'show'">
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.mediumHeight") }}:</h3>
                    {{ item.mediumHeight || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.mediumTemperature") }}:</h3>
                    {{ item.mediumTemperature || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.locationOwner") }}:</h3>
                    {{ item.locationOwner || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.classificationType") }}:</h3>
                    {{ findClassification(item) }}
                  </div>
                </v-col>
              </v-row>
              <v-form v-if="['new', 'edit'].includes(action)" ref="formMain">
                <v-row>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.mediumHeight"
                      :rules="onlyPositiveNumberFormat"
                      :label="$t('storage.fields.mediumHeight')"
                      class="required"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      type="number"
                    />
                  </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.mediumTemperature"
                        :label="$t('storage.fields.mediumTemperature')"
                        class="required"
                        color="primary"
                        onkeypress="return (event.charCode != 34)"
                        required
                        dense
                        outlined
                        type="number"
                      />
                    </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.locationOwner"
                        :label="$t('storage.fields.locationOwner')"
                        class="required"
                        color="primary"
                        onkeypress="return (event.charCode != 34)"
                        required
                        dense
                        outlined
                        type="text"
                      />
                    </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-select
                        v-model="item.classificationType"
                        :items="classifications"
                        :label="$t('storage.fields.classificationType')"
                        item-value="id"
                        item-text="name"
                        item-color="primary"
                        class="required"
                        color="primary"
                        required
                        dense
                        outlined
                        clearable
                      />
                    </span>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import _ from "lodash";
  import { mapGetters } from "vuex"
  import LanguajeService from "@/services/LanguajeService";

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {},
    name: "TabExtraDataLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    watch: {
      'item.mediumHeight'(newValue) {
        if (newValue === "") {
          this.item.mediumHeight = null;
        }
      },
      'item.mediumTemperature'(newValue) {
        if (newValue === "") {
          this.item.mediumTemperature = null;
        }
      },
    },

    async created() {
      await this.$store.dispatch('storage/fetchClassificationTypes');
    },

    computed: {
      ...mapGetters({
        onlyPositiveNumberFormat: "general/onlyPositiveNumberFormat",
        classifications: "storage/GET_CLASSIFICATIONS",
      }),
    },

    methods: {
      findClassification(item) {
        const classification = _.find(this.classifications, { id: item.classificationType });

        if (classification) {
          return this.translate(classification.languageKey);
        } else {
          return '-';
        }
      },
      translate(key) {
        return LanguajeService.getKey3(key);
      },
    }
  }
</script>

<style scoped lang="scss">
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg)
      brightness(400%) contrast(88%);
  }
  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  :deep(.v-input--selection-controls.v-input) {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    align-items: center !important;
  }

  :deep(.v-input--hide-details > .v-input__control > .v-input__slot) {
    display: contents;
  }

  :deep(.v-input__control) {
    align-items: flex-end;
  }

  :deep(.theme--light.v-label) {
    left: 0px;
    right: auto;
    position: relative;
    width: 250px;
  }
</style>
